import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const StepsSection = ({ site, sectionStyle }) => {

  return (
    <>
      {sectionStyle === "1" &&
        <>
          <section id="steps" className="bg-silver-light">
            <div className="container pt-90 pb-90">
              <div className="section-title">
                <div className="row justify-content-md-center">
                  <div className="col-md-8">
                    <div className="text-center mb-60">
                      <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                        <div className="title-wrapper">
                          <h2 className="title"> <span>Notre </span> <span className="text-theme-colored1">processus</span></h2>
                          <div className="title-seperator-line"></div>
                          <div className="paragraph">
                            <p>Ensemble, offrons à votre projet toutes les garanties d'une mise en œuvre ou rénovation réussie.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-content mt-30">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="tm-sc tm-sc-working-steps working-steps-horizontal working-steps-items-four">
                      <div className="working-steps-bg-holder"></div>
                      <div className="working-steps-inner clearfix">
                        <div className="tm-sc tm-sc-working-steps-item tm-animation move-left">
                          <div className="working-steps-item-inner">
                            <div className="image-holder-wrapper">
                              <div className="image-holder">
                                <div className="image-holder-inner">
                                  <StaticImage src="../../images/steps/preparation-projet-roanne-pcc-batiment.png" alt="Préparation d'un chantier par PCC Bâtiment à Roanne" />
                                </div>
                                <div className="tag">01</div>
                              </div>
                            </div>
                            <div className="content-holder">
                              <div className="title-holder">
                                <h5 className="title">Préparation</h5>
                              </div>
                              <div className="text-holder">
                                <p>Nous étudions ensemble votre projet</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tm-sc tm-sc-working-steps-item tm-animation move-up">
                          <div className="working-steps-item-inner">
                            <div className="image-holder-wrapper">
                              <div className="image-holder">
                                <div className="image-holder-inner">
                                  <StaticImage src="../../images/steps/planification-projet-roanne-pcc-batiment.png" alt="Planification d'un chantier par PCC Bâtiment à Roanne" />
                                </div>
                                <div className="tag">02</div>
                              </div>
                            </div>
                            <div className="content-holder">
                              <div className="title-holder">
                                <h5 className="title">Planification</h5>
                              </div>
                              <div className="text-holder">
                                <p>Nous définissons les besoins, les objectifs et les délais</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tm-sc tm-sc-working-steps-item tm-animation move-down working-steps-item-highlighted">
                          <div className="working-steps-item-inner">
                            <div className="image-holder-wrapper">
                              <div className="image-holder">
                                <div className="image-holder-inner">
                                  <StaticImage src="../../images/steps/execution-projet-roanne-pcc-batiment.png" alt="Exécution d'un chantier par PCC Bâtiment à Roanne" />
                                </div>
                                <div className="tag">03</div>
                              </div>
                            </div>
                            <div className="content-holder">
                              <div className="title-holder">
                                <h5 className="title">Exécution</h5>
                              </div>
                              <div className="text-holder">
                                <p>Notre équipe passe à l'action et concrétise votre projet</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tm-sc tm-sc-working-steps-item tm-animation move-right working-steps-push-right">
                          <div className="working-steps-item-inner">
                            <div className="image-holder-wrapper">
                              <div className="image-holder">
                                <div className="image-holder-inner">
                                  <StaticImage src="../../images/steps/livraison-projet-roanne-pcc-batiment.png" alt="Livraison d'un chantier par PCC Bâtiment à Roanne" />
                                </div>
                                <div className="tag">04</div>
                              </div>
                            </div>
                            <div className="content-holder">
                              <div className="title-holder">
                                <h5 className="title">Livraison</h5>
                              </div>
                              <div className="text-holder">
                                <p>Les travaux sont achevés, le chantier vous est livré</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }
    </>
  )
}

export default StepsSection