import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import AboutSection from '../components/section/About';
//import NewAddressSection from '../components/section/NewAddress';
import ServicesSection from '../components/section/Services';
import RealisationsSection from '../components/section/Realisations';
import StepsSection from '../components/section/Steps';
import CountsSection from '../components/section/Counts';
//import TimelineSection from '../components/section/Timeline';
import RSESection from '../components/section/RSE';
import TeamSection from '../components/section/Team';
import PartnersSection from '../components/section/Partners';
import TestimonialsSection from '../components/section/Testimonials';

const AboutPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/a-propos-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`À propos | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription={`${query.site.siteMetadata.title} met tout en œuvre afin de réaliser vos projets de plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure.`}
      pageKeywords="pcc bâtiment, roanne, coteau, loire, travaux, ravalement de façade, rénovation, plâtrerie, peinture, décoration, aménagement, isolation, Qualibat RGE"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">À propos</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">À propos</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <AboutSection site={query.site} sectionStyle="2" />
      {/*<NewAddressSection site={query.site} sectionStyle="1" />*/}
      <ServicesSection site={query.site} sectionStyle="2" />
      <RealisationsSection site={query.site} sectionStyle="1" />
      <StepsSection site={query.site} sectionStyle="1" />
      <CountsSection site={query.site} sectionStyle="2" />
      {/*<TimelineSection site={query.site} sectionStyle="1" />*/}
      <RSESection site={query.site} sectionStyle="1" />
      <TeamSection site={query.site} sectionStyle="1" />
      <PartnersSection site={query.site} sectionStyle="1" />
      <TestimonialsSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default AboutPage
